<template>
  <div class="container">
    <section>
      <b-carousel
        id="carousel-1"
        :interval="4000"
        controls
        indicators
        background="#ababab"
        style="text-shadow: 1px 1px 2px #333;"
      >
        <b-carousel-slide
          style="height:300px; opacity: 0.5;"
          :img-src="imgPath + 'business.png'"
        ></b-carousel-slide>
        <b-carousel-slide
          style="height:300px; opacity: 0.5;"
          :img-src="imgPath + 'coperate.png'"
        >
        </b-carousel-slide>
        <b-carousel-slide
          style="height:300px; opacity: 0.5;"
          :img-src="imgPath + 'home-loan.png'"
        ></b-carousel-slide>
        <b-carousel-slide
          style="height:300px; opacity: 0.5;"
          :img-src="imgPath + 'savings.png'"
        ></b-carousel-slide>
      </b-carousel>
    </section>

    <section>
      <h2 class="text-center font-weight-bold my-5">What we offer</h2>
      <div class="d-flex justify-content-center align-items-center">
        <div class="col-12 col-md-4">
          <b-card
            title="Loan Calculator"
            :img-src="imgPath + 'calculator-bg.png'"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
            <b-card-text>
              *Please note that the amount displayed is an approximate value.<br />
              *Amount is subjected to change as per the prevailing tax rates.
            </b-card-text>

            <b-button href="#" variant="primary" v-b-modal.cal_modal
              >Go Calculator</b-button
            >
          </b-card>
        </div>
        <!-- <div class="col-12 col-md-4">
          <b-card
            title="Card Title"
            img-src="https://picsum.photos/600/300/?image=25"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
            <b-card-text>
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </b-card-text>

            <b-button href="#" variant="primary">Go somewhere</b-button>
          </b-card>
        </div> -->
        <!-- <div class="col-12 col-md-4">
          <b-card
            title="Card Title"
            img-src="https://picsum.photos/600/300/?image=25"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
            <b-card-text>
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </b-card-text>

            <b-button href="#" variant="primary">Go somewhere</b-button>
          </b-card>
        </div> -->
      </div>
    </section>
    <calculator-modal></calculator-modal>
  </div>
</template>

<script>
// import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
  components: {
    calculatorModal: () =>
      import(
        /* webpackChunkName: "calculator" */ "../widgets/Calculator.Widget"
      )
  },
  name: "Home",
  data() {
    return {
      imgPath: "/images/banners/"
    };
  }
  // mounted() {
  //   this.$store.dispatch(SET_BREADCRUMB, [{ title: "Home" }]);
  // }
};
</script>
